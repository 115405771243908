<template>
	<div class="box">
		<div class="row">
			<div class="col-4">
				<label>{{ $t('monte.congelation.centre_congelation') }}</label>
				<e-select
					id="campagne_centre"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('monte.congelation.selectionner_centre')"
					:options="all_centres"
					:searchable="true"
					:show-labels="false"
					:multiple="true"
					v-model="centres"
				>
					<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} <template v-if="option.numero">({{ option.numero }})</template></template>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} <template v-if="option.numero">({{ option.numero }})</template></template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col-8">
				<DateRangeV2
	                :start.sync="start_date"
	                :end.sync="end_date"
	                :dateSelect="true"
	                :periodToShow="['personalise','jour','mois','mois_dernier']"
	                @submit="load_statistiques"
	            />
			</div>
		</div>
		<CustomTable
			ref="table"
			id_table="congelation_statistiques_lot"
			:items="statistiques"
			primaryKey="uuid"
			:busy.sync="table_busy"
            :hrefsRoutes="config_table_hrefs"
            :externSlotColumns="['articles']"
		>
			<template v-slot:[`custom-slot-cell(articles)`]="{ data }">
				<div v-for="article in data.articles" :key="article.actesarticles_id">
                    {{ article.articles_label }} - {{ article.actesarticles_ht }} x {{ article.actesarticles_quantite }}
                </div>
			</template>
		</CustomTable>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"

	export default {
		name: 'StatistiquesProductionLot',
		mixins: [Congelation],
		data () {
			return {
				start_date: new Date(new Date().setMonth(new Date().getMonth() - 3)),
				end_date: new Date(),
				all_centres: [],
				centres: [],
				table_busy: false,
				statistiques: [],
				config_table_hrefs: {
					'stallion.horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'stallion.horse_id'
						}
					},
					'centre.tiers_rs': {
						routeUniqueName: 'centreFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'centre.tiers_id'
						}
					},
					'proprietaire.tiers_rs': {
						routeUniqueName: 'proprietaireFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'proprietaire.tiers_id'
						}
					},
				}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.all_centres = await this.loadCentreCollecte()
			},

			async load_statistiques() {
				if(!this.start_date || !this.end_date || this.centres.length == 0) {
					return false
				}
				const centres_ids = this.centres.map(centre => centre.tiers_id).join(',')

				this.table_busy = true
				this.statistiques = await this.getStatistiqueProductionLot(centres_ids, this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
				this.table_busy = false
			}
		},

		components: {
	        DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>